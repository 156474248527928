<!--
 * @Description: 新增、修改餐厅
 * @Author: 琢磨先生
 * @Date: 2022-10-22 10:58:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-22 11:22:04
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    size="1100px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="dining-drawer"
    :destroy-on-close="true"
  >
    <el-tabs>
      <el-tab-pane label="基本信息">
        
      </el-tab-pane>
      <el-tab-pane label="图片" :disabled="!model.id"></el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      drawer: true,
      title: "新增餐厅门店",
      model: {},
    };
  },
  props: ["item"],
  emits: ["close"],
  watch: {
    item: {
      handler() {
        this.model = Object.assign({}, this.item);
        if (this.model.id) {
          this.loadDtl();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 获取详情
     */
    loadDtl() {},
  },
};
</script>


<style >
.dining-drawer .el-drawer__body {
  padding-top: 0;
}

.dining-drawer .el-tabs .tab-title {
  margin-right: 5px;
}

.dining-drawer .el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dining-drawer .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}

.dining-drawer .el-tab-pane {
  height: 100%;
}

.dining-drawer .el-form-item {
  margin-bottom: 30px;
}
</style>


