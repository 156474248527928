<!--
 * @Description: 餐厅&店铺
 * @Author: 琢磨先生
 * @Date: 2022-10-21 14:24:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-22 11:18:13
-->

<template>
    <el-card class="box">
      <el-form :model="query" ref="query" :inline="true">
        <el-form-item label="关键字">
          <el-input v-model="query.q" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="query.status" clearable>
            <el-option v-for="item in enums.dining_room_status" :key="item.value" :label="item.text" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
  
        <el-form-item>
          <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button>
      </div>
    </el-card>
    <el-card class="box data" v-loading="loading">
      <el-table :data="tableData.data" @sort-change="onSort" border>
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column label="Logo" width="120">
            <template #default="scope">
                <el-image :src="scope.row.pic_url" style="width:80px;height:60px;"></el-image>
            </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" width="200"></el-table-column>
        <el-table-column label="状态" width="100">
          <template #default="scope">
            <div>状态：{{ scope.row.status_text }}</div>
          </template>
        </el-table-column>
        <el-table-column label="营业时间" width="180">
            <template #default="scope">
                <span v-if="scope.row.is_all_day">全听营业</span>
            </template>
        </el-table-column>
        <el-table-column prop="area.name" label="城市" width="150"></el-table-column>
        <el-table-column prop="address" label="详细地址"></el-table-column>
        <el-table-column prop="create_at" label="创建时间" width="180"></el-table-column>
        <!-- <el-table-column label="状态" width="100">
          <template #default="scope">
            <el-tag :type="scope.row.is_stop ? 'danger' : 'success'">{{
              scope.row.is_stop ? "正常" : "停用"
            }}</el-tag>
          </template>
        </el-table-column>-->
        <el-table-column label="操作" fixed="right" width="140">
          <template #default="scope">
            <!-- <el-button type="primary" link size="small" v-if="scope.row.status === 0" @click="onCheck(scope.row)">审核
            </el-button>--> 
            <el-button
              type="primary"
              link
              @click="onEdit(scope.row)"
              v-if="scope.row.status === 0 || scope.row.status === 4"
            >修改</el-button>
            <el-popconfirm
              title="去定要删除？"
              @confirm="onDelete(scope.row)"
              v-if="scope.row.status === 0"
            >
              <template #reference>
                <el-button type="danger" link size="small">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="query.pageIndex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="tableData.counts"
        v-if="tableData.counts > 0"
      ></el-pagination>
    </el-card>
  <edit-dining></edit-dining>
  </template>
  
  <script>
  import common_api from '@/http/common_api';
  import EditDining from './edit_dining.vue'
  export default {
    components: {
      EditDining
    },
    data() {
      return {
        loading: false,
        title: "",
        //显示导入弹框
        visibleDialog: false,
        saving: false,
        query: {
          pageIndex: 1,
          pageSize: 20,
          order: {
            sort: "",
            fieldName: "",
          },
          q: "",
          status:''
        },
        tableData: {
          counts: 0,
        },
        enums:{}, 
        //
        current:null,
      };
    },
    created() {
    common_api.get_enums().then(res=>{
        if(res.code==0){
            this.enums= res.data;
        }
    })
   
      this.loadData();
    },
    methods: {
      
  
      /**
       * 加载数据
       */
      loadData() {
        this.current = null;
        this.loading = true;
        this.$http.post("seller/v1/diningRoom", this.query).then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
          this.loading = false;
        });
      },
      /**
       * 搜索
       */
      onSearch() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      /**
       * 排序
       */
      onSort(e) {
        if (e.prop) {
          this.query.order.sort = e.order;
          this.query.order.fieldName = e.prop;
          this.loadData();
        }
      },
      /**
       * 分页页数更改
       */
      pageSizeChange(val) {
        this.query.pageSize = val;
        this.loadData();
      },
      /**
       * 分页页码更改
       */
      pageCurrentChange(val) {
        this.query.pageIndex = val;
        this.loadData();
      },
      /**
       * 新增、修改
       */
      onEdit(item) {
        this.current= Object.assign({},item);

      },
  
      /**
       * 删除
       * @param {*} item
       */
      onDelete(item) {
        this.$http
          .get("seller/v1/expense/record/del?id=" + item.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message({ type: "success", message: res.msg });
              this.loadData();
            }
          });
      },
       
       
    },
  };
  </script>
  
  <style scoped>
  </style> 